#po-report {
    border-collapse: collapse;
    width: 100%;
}

#po-report td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#po-report th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    border: 1px solid #ddd;
    padding-left: 8px;
}